<template lang="pug">
v-card.mb-12(light, flat)
    v-card-text
        v-simple-table.userTable.striped.hoverable(dense)
            template(v-slot:default)
                thead
                    tr
                        th Category
                        th Name
                        th Default
                        th Minimum
                        th Maximum
                tbody
                    tr(v-for='markup in markups', :key='markup.id')
                        td {{ markup.category }}
                        td {{ markup.name }}
                        td
                            v-text-field(type='number', v-model.number='markup.default', suffix='%', :min='markup.min', :max='markup.max')
                        td
                            v-text-field(type='number', v-model.number='markup.min', suffix='%')
                        td
                            v-text-field(type='number', v-model.number='markup.max', suffix='%')
    v-card-actions
        v-spacer
        v-btn(text, @click='undoChanges') Undo Changes
        v-btn.white--text(color='green', @click='saveMarkup') Save



    v-overlay(absolute, :value='busy')
        .text-h6 Please Wait...
        v-progress-linear(indeterminate)
    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
</template>

<script>
import _ from 'lodash';
export default {
    data (){
        return {
            dti,
            message: '',
            busy: false,
            markups: []
        };
    },
    methods: {
        async saveMarkup () {
            let results = await this.sendCommand({
                action: 'updateMarkups',
                parameters: this.markups
            });

            if (results) {
                this.message = `Markup updated.`;
            } else {
                this.message = `Failed to update markup.`;
            }
        },
        async undoChanges () {
            this.fetchData();
        },
        async fetchData () {
            this.socketEmit('getMarkupData', null, (results) => {
                this.markups = results;
            });
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            }
        }
    },
    mounted (){
        this.fetchData();
    }
};

</script>

<style lang="scss">
</style>
