<router>
{
    name: 'Sales Tool Markup',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col
            Markup

    //- v-btn(primary, @click="$auth.logout('aad')") Sign out
</template>
<script>

import Markup from '@/components/salestool/markup.vue';
export default {
    meta: {
        role: 'satmarkup'
    },
    components: {
        Markup
    },
    data () {
        return {
            cards: [
                { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 12 },
                { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
                { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
            ],
            tab: 0
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
    }
};
</script>

<style scoped>
</style>
